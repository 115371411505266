// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import DeepLinking from "../../blocks/DeepLinking/src/DeepLinking";
import AutomaticRenewals from "../../blocks/AutomaticRenewals/src/AutomaticRenewals";
import Settings from "../../blocks/Settings/src/Settings";
import PreviewVideo from "../../blocks/PreviewVideo/src/PreviewVideo";
import AdManager from "../../blocks/AdManager/src/AdManager";
import GraphicalCharts from "../../blocks/GraphicalCharts/src/GraphicalCharts";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import LiveFeedScheduling from "../../blocks/LiveFeedScheduling/src/LiveFeedScheduling";
import AudioMusic from "../../blocks/AudioMusic/src/AudioMusic";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import LandingPage from "../../blocks/LandingPage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import AutomatedEmailSending from "../../blocks/AutomatedEmailSending/src/AutomatedEmailSending";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SearchEngineOptimisationseo from "../../blocks/SearchEngineOptimisationseo/src/SearchEngineOptimisationseo";
import ApiIntegration from "../../blocks/ApiIntegration/src/ApiIntegration";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import Search from "../../blocks/Search/src/Search";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import OfflineBrowsing from "../../blocks/OfflineBrowsing/src/OfflineBrowsing";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Analytics from "../../blocks/Analytics/src/Analytics";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LiveFeedCapture from "../../blocks/LiveFeedCapture/src/LiveFeedCapture";
import FreeTrial from "../../blocks/FreeTrial/src/FreeTrial";
import Referrals from "../../blocks/Referrals/src/Referrals";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import Maps from "../../blocks/Maps/src/Maps";



const routeMap = {
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
DeepLinking:{
 component:DeepLinking,
path:"/DeepLinking"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
Settings:{
 component:Settings,
path:"/Settings"},
PreviewVideo:{
 component:PreviewVideo,
path:"/PreviewVideo"},
AdManager:{
 component:AdManager,
path:"/AdManager"},
GraphicalCharts:{
 component:GraphicalCharts,
path:"/GraphicalCharts"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
PushNotifications:{
 component:PushNotifications,
path:"/PushNotifications"},
LiveFeedScheduling:{
 component:LiveFeedScheduling,
path:"/LiveFeedScheduling"},
AudioMusic:{
 component:AudioMusic,
path:"/AudioMusic"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
DynamicContent:{
 component:DynamicContent,
path:"/DynamicContent"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
AutomatedEmailSending:{
 component:AutomatedEmailSending,
path:"/AutomatedEmailSending"},
BreadcrumbNavigation:{
 component:BreadcrumbNavigation,
path:"/BreadcrumbNavigation"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
SearchEngineOptimisationseo:{
 component:SearchEngineOptimisationseo,
path:"/SearchEngineOptimisationseo"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
Search:{
 component:Search,
path:"/Search"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LiveFeedCapture:{
 component:LiveFeedCapture,
path:"/LiveFeedCapture"},
FreeTrial:{
 component:FreeTrial,
path:"/FreeTrial"},
Referrals:{
 component:Referrals,
path:"/Referrals"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
SplitPayments:{
 component:SplitPayments,
path:"/SplitPayments"},
Maps:{
 component:Maps,
path:"/Maps"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;